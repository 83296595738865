#nav_bar{
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10;
	background-color: #ffffff;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	min-height: max-content;

}
.logo {
	position:absolute;
	width: 200px;
	cursor: pointer;
	left:5%;
	top:-85%;
}

.ul{
	display: flex;
	justify-content: center;
	align-items: center;
	list-style-type:none;
	margin: 0 4rem;
	padding: 0;
	overflow: hidden;
	background-color:rgb(255, 255, 255);
}

.li {
	display: flex;
	align-items: center;
	text-decoration: none;
}

.navBtn{
	cursor: pointer;
	display:block;
	color: #333333;
	text-align:center;
	min-width: max-content;
	padding: 20px 20px;
	text-decoration: none;
}

.navBtn:hover{
	color: #333333;
	background-color: #F0F0F0;
	text-decoration: none;
}

.btn_name{
	margin: 0;
	padding: 0;
}

.account{
	list-style: none;
	display: flex;
}

.account_btn{
	text-decoration: none;
	position: absolute;
	top: 25%;
	right: 12%;
	display: flex;
   align-items: center;
   justify-content: center;
   border: none;
   margin-right: 15px;
   background: linear-gradient(90deg, #C271BD, #8A3585);
   font-size: 1rem;
   padding: 0.3rem 1rem;
   color: white;
   box-shadow: 0px 6px 15px rgba(0,0,0,0.05);
   border-radius: 0.8rem;

	&:hover{
		background: linear-gradient(90deg, #C271BDe0, #8A3585e0);
	}
}

.toggle_button{
	margin-right: 1rem;
	width: min-content;
	display: none;
}

.navbar_active {
	background-color:  #F0F0F0;
  }

  .dropdown{
	  position: absolute;
	  top: 58px;
	  width: 200px;
	  transform: translate(-45%);
	 background-color: white;
	  border: 1px solid #afafaf;
	  border-radius: 8px;
	  padding: 1rem;
	  overflow: hidden;
	  margin: 0px -20px;


  }

  .menu-item{
	  height: 40px;
	  display: flex;
	  align-items: center;
	  border-radius: 8px 8px 0px 0px;
	  transition: background 400ms;
	  padding:0.5rem;



  }

  .menu-item:hover{
	color: #333333;
	background-color: rgb(238, 238, 238);
  }



@media (max-width: 1366px){
	.navBtn{
		font-size: 1rem;
	}
}

@media (max-width: 1280px){
	.navBtn{
		font-size: 1rem;
	}
}

@media (max-width: 1024px){
	.navBtn{
		font-size: 0.8rem;
	}
}

@media (max-width: 768px){
	.logo{
		display:grid;
		place-items: center;
		width:200px;
		padding-top:2%;
		margin:auto -2rem;
	}
	.ul{
		display: none;
	}
	.toggle_button{
		padding: 0.8rem 0;
		display: block;
	}
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (orientation : portrait){
	.logo{
		margin:  auto -1rem;
	}
}
