.blog_slider{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.slider_btn_container{
    width: min-content;
}

.slider_btn{
    margin: 0 8rem;
    cursor: pointer;
    border-radius: 50%;
    text-decoration: none;
    border: none;
    outline: none;
    background-color: #E6E6E6;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 1rem;
    padding: 1rem;
}

.slick-slider{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1440px) {
    .slider_btn{
        margin: 0 4rem;
    }
}

@media (max-width: 1024px) {
    .blog_slider{
        width: 80%;
    }
}

@media (max-width: 768px) {
    .blog_slider{
        width: 90%;
    }
}
