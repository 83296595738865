#compare{
	position: relative;
	z-index: 0;
	top: -5vh;
	width: 100%;
	height: 85vh;
	background-color: #EFE0EE;
	display: flex;
	align-items: center;
	border-bottom-left-radius: 50px;
	border-bottom-right-radius: 50px;
	box-shadow: 0px 6px 30px rgba(0,0,0,0.1);
}

.comapare_container{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.compare_content{
	display: flex;
	align-items: center;
	justify-content: center;
}

.compareImg{
	width: 400px;
}

.leftContainer{
	width: 35vw;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.rightContainer{
	width: 60vw;
	display: flex;
	justify-content: space-around;
}

.cards{
	display: flex;
	align-items: center;
}

.heroTitle{
	font-family: gilroy-extra-bold;
	font-size: 2.7em;
	color: #333333;
	margin-bottom: 1.5em;
	margin-top: 0;
}

#nine-eye{
	color: #8A3585;
}

@media (max-width: 1440px) {
	.compareImg{
		width: 350px;
	}
	.heroTitle{
		font-size: 2.5rem;
	}
}

@media (max-width: 1024px) {
	.compareImg{
		width: 300px;
	}
	.heroTitle{
		font-size: 2.2rem;
	}
}

@media (max-width: 1024px) and (orientation: portrait) {
	#compare{
		padding: 3rem 0;
		height: max-content;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.leftContainer{
		width: auto;
	}
	.rightContainer{
		width: auto;
	}
	.compareImg{
		width: 500px;
	}
	.heroTitle{
		margin-top: 6rem;
		font-size: 3.2rem;
	}
	.cards{
		flex-direction: column;
	}
	.compare_content{
		flex-direction: column;
	}
}

@media (max-width: 768px) {
	#compare{
		padding: 3rem 0;
		height: max-content;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.leftContainer{
		width: auto;
	}
	.rightContainer{
		width: auto;
	}
	.compareImg{
		width: 350px;
	}
	.heroTitle{
		margin-top: 6rem;
		font-size: 2.2rem;
	}
	.cards{
		flex-direction: column;
	}
	.compare_content{
		flex-direction: column;
	}
}

@media (max-width: 600px) {
	.compareImg{
		width: 250px;
	}
}
