.search_box {
  margin-top: 6vh;
  margin-bottom: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading {
  width: 30rem;
  justify-content: space-between;
  margin-bottom: 2rem;
  color: #333333;
  font-family: gilroy-extra-bold;
  font-size: 2.5rem;
}

.TopHeading {
  display: none;
}

.nine_eye {
  color: #771572;
}

.search_form {
  display: flex;
  align-items: center;
}

.search_items {
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: column;
}
.search_row {
  display: flex;
}
.search_droplist {
  width: 14rem;
  align-self: center;
  margin-bottom: 8px;
  margin-top: 3px;
  margin-left: 10px;
}

.search_input {
  position: relative;
  font-family: gilroy-regular;
  margin: 20px 0;
  background-color: #f0f0f0;
  font-size: 17px;
  padding: 0.8rem 2rem;
  border-radius: 60px;
  color: #333333;
  border: none;
  width: 11vw;
}
.search_inputerror {
  position: relative;
  right: -10%;
  font-family: gilroy-regular;
  margin: 20px 0;
  box-shadow: inset 0 0 0 2px #fcbdb8ff;
  border: none;
  background-color: #f0f0f0;
  font-size: 17px;
  padding: 0.8rem 2rem;
  border-radius: 60px;
  color: #333333;
  width: 35vw;
}

.search_buttons {
  position: relative;
  display: flex;
}

.search_button {
  position: relative;
  padding: 0.8rem 4rem 0.8rem 2rem;
  box-shadow: -4px 0px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-family: gilroy-extra-bold;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #c271bd, #8a3585);
  font-size: 12pt;
  color: white;
  border: none;
  min-width: max-content;
}

.gps_button {
  position: relative;
  z-index: 2;
  right: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: linear-gradient(90deg, #c271bd, #8a3585);
  font-size: 12pt;
  color: white;
  box-shadow: -4px 0px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  padding: 0.7rem;
}
.search_button:hover {
  box-shadow: -4px 0px 20px rgba(0, 0, 0, 0.15);
  background: linear-gradient(90deg, #c271bde9, #8a3585e9);
}

.gps_button:hover {
  box-shadow: -4px 0px 20px rgba(0, 0, 0, 0.15);
  background: linear-gradient(90deg, #c271bde9, #8a3585e9);
}

#gps {
  fill: #ffffff;
  width: 1.5rem;
}

@media (max-width: 1280px) {
  .heading {
    width: 23rem;
    font-size: 2.2rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 1024px) {
  .heading {
    width: 21rem;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  .search_input {
    margin: 10px 0;
    font-size: 15px;
    padding: 0.8rem 1.5rem;
    width: 40vw;
  }
  .search_button {
    font-size: 10pt;
    padding: 0.8rem 3rem 0.8rem 1rem;
  }
  .gps_button {
    padding: 0.5rem;
  }
}

@media (max-width: 600px) {
  .heading {
    font-size: 1.2rem;
    width: 12.7rem;
  }
  .search_input {
    padding: 0.6rem 1.5rem;
    font-size: 1rem;
  }
  .search_buttons {
    max-height: 2.5rem;
  }
  .search_button {
    padding: 0.7rem 3.5rem 0.7rem 1.5rem;
    font-size: 10pt;
  }
  .gps_button {
    padding: 0.6rem;
  }
  #gps {
    width: 1.2rem;
  }
}
@media only screen and (max-width: 1440px) {
  .search_items {
    position: relative;
    display: block;
  }
  .search_buttons {
    position: relative;
    right: 0rem;
  }
}
@media only screen and (max-width: 768px) {
  .search_droplist {
    margin-left: 0px;
  }
  .search_row {
    display: block;
  }
}
@media only screen and (min-width: 1024px) {
  .search_input {
    margin: 10px 0;
    font-size: 15px;
    padding: 0.8rem 1.5rem;
    width: 20vw;
  }
  .search_form {
    margin-left: 9rem;
  }
}
@media only screen and (min-width: 1440px) {
  .search_form {
    margin-left: 5rem;
  }
}
@media only screen and (min-width: 1660px) {
  .search_form {
    margin-left: 10rem;
  }
  .search_buttons {
    margin-left: -11rem;
  }
}
