@font-face {
  src: url("./components/assets/fonts/Gilroy-ExtraBold.ttf");
  font-family: gilroy-extra-bold;
}

@font-face {
  src: url("./components/assets/fonts/Gilroy-Bold.ttf");
  font-family: gilroy-bold;
}

@font-face {
  src: url("./components/assets/fonts/Gilroy-RegularItalic.ttf");
  font-family: gilroy-regular-italic;
}

.App {
  background-color: #f8f8f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

* {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

textarea:focus {
  outline: none !important;
}

button:focus {
  outline: none !important;
}




.close_button {
  border-radius: 50%;
  background-color: #eb8888;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 10px 0 0 0;
}

.close_button:hover {
  background-color: #eb8888d9;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 10;
  padding: 40px 30px;
  border-radius: 30px;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.16);
}

.modal h3,
.modal h1 {
  text-align: center;
  padding: 0;
  font-weight: 900;
  margin: 0 0 30px 0;
  border: none;
}

.modal input {
  display: block;
  font-family: gilroy-regular;
  margin: 0px 10px 20px 10px;
  background-color: #f0f0f0;
  font-size: 17px;
  padding: 0.8rem 2rem;
  border-radius: 60px;
  color: #333333;
  border: none;
  width: 20rem;
}

.addressbar input {
  font-size: 22px;
  width: 23rem;
}

.citystate {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.citystate input {
  width: 9rem;
}

.modal button {
  margin-right: 6%;
}

.small-screen {
  display: block;
}

.info {
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
  margin: 10px;
  position: relative;
}
.info button {
  position: absolute;
  right: 5%;
  top: 3%;
}


.add {
  color: #8a3585;
  font-size: large;
}

.button:hover {
  background: linear-gradient(90deg, #c271bde9, #8a3585e9);
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.16);
}
.table {
  display: none;
}
.css-yk16xz-control{
  border-radius: 60px !important;
  height: 45.59px;
  font-size: 15px !important;
  border-color: #8a3585 !important; 
}
.css-1pahdxg-control{
  border-radius: 60px !important;
  height: 45.59px;
  border-color: #8a3585 !important;
}
.css-g1d714-ValueContainer{
  height: 45.59px;
  border-color: #8a3585 !important;
}
.css-1uccc91-singleValue{
  margin-left: 20px !important;
  font-family: gilroy-regular;
  color: black;
  margin-top: -7px;
  font-weight: 400;
}
.css-1g6gooi {
  margin-top: -10px !important;
}
.css-1wy0on6 {
  margin-top: -24px;
}
span.css-1okebmr-indicatorSeparator {
  height: fit-content;
}
.css-tlfecz-indicatorContainer {
  margin-top: 5px;
}
.css-1wa3eu0-placeholder{
  margin-left: 20px !important;
  font-family: gilroy-regular;
  color: black !important;
  font-weight: 400;
  margin-top: -7px;
}

.css-b8ldur-Input{
  margin-top: -10px !important;
  font-family: gilroy-regular;
  color: black;
  font-weight: 400;
}

@media (max-width: 800px) {
  .info {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .modal input {
    width: 14rem;
    margin: 0 0 30px 0;
  }
  .addressbar input {
    width: 20rem;
  }
  .addressbar button {
    position: relative;
    left: -5%;
  }

  .citystate input {
    width: 7rem;
  }

  .modal button {
    margin-right: 18%;
  }


  .container {
    display: block;
  }
}

@media (max-width: 380px) {
  h1 {
    font-size: 27px;
    margin: 30px 0 0 0;
    padding: 0 0 -20px 0;
  }
  .info,
  li {
    font-size: 16px;
  }

  .modal input {
    width: 14rem;
    margin: 0 0 30px 0;
  }
  .addressbar input {
    width: 14rem;
    margin: 0 0 30px 0;
  }
  .addressbar button {
    position: relative;
    left: -5%;
  }

  .citystate input {
    width: 4.6rem;
  }

  .par {
    font-size: 16px;
  }
}

