.signup_page{
    height: 85vh;
    width: 100%;
    margin-top: 3.2rem;
    background-color: #F8F8F8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup_container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.heading_signup{
  font-size: 2.5rem;
  font-family: gilroy-extra-bold;
  color: #333333;
}

.signup_form{
  display: flex;
  flex-direction: column;
  align-items: center;
}



.name{
  width: 34rem;
  display: flex;
  justify-content: space-between;
}

.fn, .ln{
  width: 12rem !important;
}

.signup_input{
  width: 30rem;
  margin-bottom: 0.8rem;
  appearance:none;
  background-color: #E9E9E9;
  font-size: 17px;
  padding: 1rem 2rem;
  border-radius: 60px;
  color: #333333;
  border:none;
  font-family: gilroy-regular;
}

.error{
  background-color: #fffafa;
  color: #c92432;
  border: solid 1px red;
}



.button_signup{
  cursor: pointer;
  border-radius: 60px;
  background: linear-gradient(90deg, #C271BD, #8A3585);
  border: none;
  color: #F8F8F8;
  font-size: 1.1rem;
  font-family: gilroy-extra-bold;
  padding: 10px 25px;

  &:hover{
    background: linear-gradient(90deg, #C271BDe9, #8A3585e9);
    box-shadow: 3px 3px 20px rgba(0,0,0,0.16);

  }
}

.login_link{
  text-decoration: none;
  color: #333333;
  margin-top: 1rem;
  margin-bottom: 1rem;
  &:hover{
    color: #B66AB1;
  }
}

.signup_error{
    color: red;
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: center;
}



@media only screen and (max-width: 1366px){
  .signup_page{
    height: 100vh;
  }
}
@media only screen and (max-width: 1280px){
  .signup_page{
    height: 100vh;
  }
  .button_signup{
  padding: 0.6rem 1.5rem;
  font-size: 1rem;
  margin-bottom: 3rem;
  }
  .login_link{
    margin-top: -1rem;
    margin-bottom: 2rem;
  }
  .signup_input{
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1024px){
  .heading_signup{
    font-size: 2rem;
  }
  .signup_page{
    height: 95vh;
  }
  .login_link{
    margin-top: -2rem;
  }
  .signup_input{
    padding: 1rem 2rem;
    font-size: 13px;
    margin-bottom: 1.2rem;
  }
  .button_signup{
    padding: 8px 25px;
    font-size: 0.9rem;
  }
}

@media (max-width: 600px){
  .heading_signup{
    font-size: 1.5rem;
    margin-bottom: 0;
  }
  .signup_input{
    padding: 1rem 2rem;
    font-size: 13px;
    margin-bottom: 0.3rem;
    width: 15rem;
  }
  .signup_page{
    height: 80vh;
  }
  .fn, .ln{
    width: 5rem !important;
  }
  .name{
    width: 19rem;
  }
  .signup_error{
      width: 15rem;
      font-size: 0.9rem;
  }
  .button_signup{
    margin-top: 0.3rem;
  }
}
