@font-face{
  src: url(./assets/fonts/Gilroy-Regular.ttf);
  font-family: gilroy-regular;
}

abbr {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
}

.react-calendar {
  width: 30rem;
  height: max-content;
  padding: 30px 40px;
  max-width: 100%;
  border-radius: 4rem;
  font-family: gilroy-regular;
  background: linear-gradient(135deg, #E6E6E6, #EFEFEF);
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5rem;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5rem;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button:not(.react-calendar__tile) {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}


.react-calendar__navigation {
  height: 40px;
  margin-bottom: 1rem;
}

.react-calendar__navigation button {
  font-family: gilroy-regular;
  min-width: 40px;
  background: none;
  border-radius: 30px;
  color: #333333;

}

.react-calendar__navigation button:enabled:hover{
  background-color: #D9D9D9;
}

.react-calendar__navigation button:enabled:focus {
  background-color: #D9D9D9;
}

.react-calendar__navigation button[disabled] {
  color: #292929;
  background-color: none;
}

.react-calendar__month-view__weekdays {
  color: #333333;
  text-align: center;
  margin: 5px 0px;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5rem 0rem;
}

.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}


.react-calendar__month-view__days__day--weekend {
  color: #fff;
}


.react-calendar__month-view__days__day--neighboringMonth {
  color: #AAAAAA !important;
}


.react-calendar__month-view__days__day{
  margin: 0.38rem;
  padding: 0.92rem !important;
  flex-basis: 11.2% !important;
  border-radius: 50%;
  box-shadow: -2px -2px 6px rgba(255,255,255, 0.8),
              2px 2px 8px rgba(0, 0, 0, 0.15);
  font-family: gilroy-regular;
  border: none;
  color: #333333;
  text-align: center;
  background: none;
}

.react-calendar__year-view__months__month, .react-calendar__decade-view__years__year, .react-calendar__century-view__decades__decade {
  margin: 0.5rem 0.62rem;
  padding: 1.38rem 0.3rem !important;
  flex-basis: 28% !important;
  border-radius: 20px;
  box-shadow: -2px -2px 6px rgba(255,255,255, 0.8),
              2px 2px 8px rgba(0, 0, 0, 0.15);
  font-family: gilroy-regular;
  border: none;
  color: #333333;
  text-align: center;
  background: none;
}





.react-calendar__tile:disabled {
  box-shadow: none;
  background-color: none;
  color: #AAAAAA;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled {
  color: #AAAAAA !important;
}

.react-calendar__tile--now {
  background-color: #aecfff;
}

.react-calendar__tile:enabled:hover:not(.react-calendar__tile--now) {
  box-shadow: -2px -2px 6px rgba(255,255,255, 0.8),
              2px 2px 8px rgba(0, 0, 0, 0.15),
              inset -2px -2px 8px rgba(255,255,255, 0.8),
              inset 2px 2px 5px rgba(0, 0, 0, 0.10);
}

.react-calendar__tile--now:enabled:hover {
  box-shadow: -2px -2px 6px rgba(255,255,255, 0.8),
              2px 2px 8px rgba(0, 0, 0, 0.15),
              inset -2px -2px 8px rgba(255,255,255, 0.5),
              inset 2px 2px 5px rgba(0, 0, 0, 0.15);
}

.react-calendar__tile:enabled:focus:not(.react-calendar__tile--now) {
  box-shadow: inset -2px -2px 8px rgba(255,255,255, 0.2),
              inset 2px 2px 6px rgba(0, 0, 0, 0.10);
}

.react-calendar__tile--now:enabled:focus {
  box-shadow: inset -2px -2px 8px rgba(255,255,255, 0.5),
              inset 2px 2px 5px rgba(0, 0, 0, 0.15);
}

.react-calendar__tile--active {
    background-color: #D6B4D4;
  box-shadow: inset -2px -2px 8px rgba(255,255,255, 0.2),
              inset 2px 2px 6px rgba(0, 0, 0, 0.10);
  color: black;
}

.react-calendar__tile--now.react-calendar__tile--active{
  box-shadow: inset -2px -2px 8px rgba(255,255,255, 0.5),
              inset 2px 2px 5px rgba(0, 0, 0, 0.25);
  color: black;
}

@media (max-width: 1440px) {
    .react-calendar{
        width: 27rem;
    }
    .react-calendar__month-view__days__day{
        margin: 0.33rem;
        padding: 0.72rem !important;
    }
}

@media (max-width: 1024px) {
    .react-calendar{
        padding: 2rem;
        width: 23rem;
    }
    .react-calendar__month-view__days__day{
        margin: 0.30rem;
        padding: 0.57rem !important;
        flex-basis: 11.1% !important;
        font-size: 0.8rem;
    }
}

@media (max-width: 768px) {
    .react-calendar{
        width: 27rem;
    }
    .react-calendar__month-view__days__day{
        margin: 0.38rem;
        flex-basis: 11.2% !important;
        margin: 0.34rem;
        padding: 0.79rem !important;
    }
}

@media (max-width: 600px) {
    .react-calendar{
        width: 20rem;
        border-radius: 2rem;
        padding: 1.5rem;
    }
    .react-calendar__month-view__days__day{
      margin: 0.25rem;
      padding: 0.44rem !important;
      flex-basis: 10.9% !important;
      font-size: 0.7rem;
    }
    .react-calendar__tile--active{
        box-shadow: inset -2px -2px 8px rgba(255,255,255, 0.8),
                    inset 2px 2px 6px rgba(0, 0, 0, 0.10);
      color: black;
    }
    .react-calendar__tile--now.react-calendar__tile--active{
      box-shadow: inset -2px -2px 8px rgba(255,255,255, 0.5),
                  inset 2px 2px 5px rgba(0, 0, 0, 0.25);
      color: black;
    }
}
