.search_card {
    margin-bottom: 2rem;
    border-radius: 1rem;
    background: #f0f0f0;
    display: flex;
    padding: 1.5rem;
    width: 30rem;
    height: min-content;
}

.search_card_img {
    flex: 2.5;
    border-radius: 0.5rem;
    width: 100%;
    min-height: 100%;
    background-color: #cbcbcb;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.store_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.search_card_details {
    flex: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-left: 2rem;
}

.shop_name {
    margin: 0;
    font-size: 1.7rem;
    font-family: gilroy-extra-bold;
    color: #333333;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.shop_class {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.shop_address {
    font-size: 0.8rem;
    margin-top: 0;
    max-height: 2rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.shop_appointment_fee{
    margin-bottom: 1rem;
    font-weight: 600;
}

.search_card_btn {
    cursor: pointer;
    font-size: 1rem;
    font-family: gilroy-extra-bold;
    padding: 0.6rem 2rem;
    border-radius: 2rem;
    border: none;
    color: #F9F9F9;
    background: linear-gradient(-90deg, #C271BD, #8A3585);
}

.search_card_btn:hover {
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.16);
    background: linear-gradient(-90deg, #C271BDe9, #8A3585e9);
}

.search_card_stars_container {
    display: flex;
    margin-bottom: 0.5rem;
}

@media (max-width: 1366px) {
    .search_card {
        margin-bottom: 2rem;
        border-radius: 1rem;
        background: #f0f0f0;
        display: flex;
        padding: 1.5rem;
        width: 25rem;
    }
    .search_card_btn {
        cursor: pointer;
        font-size: 0.9rem;
        font-family: gilroy-extra-bold;
        padding: 0.5rem 1.5rem;
        border-radius: 2rem;
        border: none;
        color: #F9F9F9;
        background: linear-gradient(-90deg, #C271BD, #8A3585);
    }
    .shop_name {
        margin: 0;
        font-size: 1.3rem;
        font-family: gilroy-extra-bold;
        color: #333333;
    }
    .shop_class {
        font-size: 0.9rem;
        margin-top: 0;
        margin-bottom: 0.5rem;
    }
}

@media screen and (max-width: 1280px) {
    .search_card {
        width: 25rem;
    }
}

@media screen and (orientation: landscape) and (max-width: 1024px) {
    .search_card {
        width: 20rem;
    }
}

@media (max-width: 600px) {
    .search_card {
        padding: 1rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 1rem;
        text-align: center;
    }
    .search_card_img {
        flex: none;
        height: 8rem;
    }
    .search_card_details {
        margin: 0;
        align-items: center;
        justify-content: center;
    }
    .shop_name {
        margin-top: 0.5rem;
        font-size: 1rem;
    }
    .shop_class {
        font-size: 0.8rem;
    }
    .search_card_btn {
        min-width: max-content;
        font-size: 0.9rem;
        margin: 0 1.7rem;
    }
    .shop_appointment_fee{
        font-size: 0.8rem;
    }
}
