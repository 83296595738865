.login_page{
    height: 70vh;
    width: 100%;
    margin-top: 3.7rem;
    background-color: #F8F8F8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading_login{
  margin-top: 0;
font-size: 2.5rem;
font-family: gilroy-extra-bold;
color: #333333;
}

.login_form{
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login_input{
  width: 25rem;
  margin-bottom: 1.5rem;
  appearance:none;
  background-color: #E9E9E9;
  font-size: 17px;
  padding: 1rem 2rem;
  border-radius: 60px;
  color: #333333;
  border:none;
  font-family: gilroy-regular;
}

.login_options_container{
  width: 25rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.login_options{
  text-decoration: none;
  color: #333333;

  &:hover{
    color: #B66AB1;
  }
}

.button_Login{
  width: min-content;
  cursor: pointer;
  border-radius: 60px;
  background: linear-gradient(90deg, #C271BD, #8A3585);
  border: none;
  color: #F8F8F8;
  font-size: 1.1rem;
  font-family: gilroy-extra-bold;
  padding: 10px 25px;

  &:hover{
    background: linear-gradient(90deg, #C271BDe9, #8A3585e9);
    box-shadow: 3px 3px 20px rgba(0,0,0,0.16);
  }
}

.login_error{
    color: red;
    margin-bottom: 1.5rem;
    text-align: center;
    margin-top: 0;
    width: max-content;
}

.alert {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #ffe0ee;
    animation-name: alert_bar;
    animation-duration: 1s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 4rem;
    padding-bottom: 1rem;
}

.alert_text{
  text-align: center;
  color: #262626;
}

.alert_a{
    cursor: pointer;
    text-decoration: underline;
    color: #771572;
}

@keyframes alert_bar {
  from {padding-top: 50px; }
  to {padding-top: 4rem;}
}

@media (max-width: 1280px){
  .button_Login{
    padding: 0.6rem 1.5rem;
    font-size: 1rem;
    margin-bottom: 3rem;
  }
  .login_input{
    font-size: 1rem;
  }
}

@media (max-width: 1024px){
  .login_input{
    padding: 0.9rem 2rem;
    width: 20rem;
    font-size: 13px;
  }
  .login_options_container{
    font-size: 12px;
    width: 20rem;
  }
  .heading_login{
    font-size: 2rem;
  }
  .button_Login{
    padding: 8px 25px;
    font-size: 0.9rem;
  }

}

@media (max-width: 600px) {
  .login_input{
    width: 15rem;
  }
  .login_options_container{
    width: 15rem;
  }
  .login_error{
      font-size: 0.9rem;
      color: red;
      width: 15rem;
  }
  .alert_text{
      width: 80%;
  }

}
