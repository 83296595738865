.legal{
    padding: 150px;
    background: linear-gradient(to bottom, #EFE0EE 0%,#EFE0EE 50%,#EFE0EE 50%,#F0F0F0 50%,#F0F0F0 100%);
}

.legal_container{
    background-color: white;
    padding: 60px;
    text-align: center;
    border-radius: 2rem;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1);
    width: 70%;
    margin: auto;
}

.legal_title{
    text-align: center;
    color: #771572;
    font-size: 2rem;
    font-family: gilroy-extra-bold;
    margin-top: 0;
}

.legal_content{
    width: 90%;
    text-align: justify;
    margin: auto;
}

.legal_content ol {
  counter-reset: item
}
.legal_content li {
  display: block
}
.legal_content li:before {
  content: counters(item, ".") ". ";
  counter-increment: item
}
.legal_content a {
    color: #771572;
}

.legal_content a:hover{
    color: #B66AB1 !important;
    text-decoration: none;
}

@media (max-width: 1366px) {
    .legal_content{
        width: 90%;
    }
}

@media (max-width: 1280px) {
    .legal_title{
        font-size: 1.8rem;
    }
}

@media (max-width: 768px) {
    .legal{
        padding: 150px 100px;
    }
    .legal_title{
        font-size: 1.5rem;
    }
}

@media (max-width: 600px) {
    .legal{
        padding: 150px 0;
    }
    .legal_container{
        padding: 50px;
    }
    .legal_content{
        width: 100%;
    }
}
