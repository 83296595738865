.blog_component{
    position: relative;
    background: #f8f8f8;
    border-radius: 2rem;
    width: 40%;
    z-index: 2;
    margin-top: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: max-content;
    padding: 0 8rem 6rem 8rem;
    box-shadow: 3px 3px 40px rgba(0, 0, 0, 0.2);
}

.upper_secton{
    position: relative;
    margin-top: -2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Blog_area{
    width: 100%;
}

.writer-img{
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.writer-img img{
    max-width: 100%;
    max-height: 100%;
    display: block;
    border-radius: 50%;
}

.Blog-heading{
    margin: 2.5rem 0;
    text-align: center;
    font-family: gilroy-extra-bold;
    font-size: 2.2rem;
    color: #262626;
}

.Blog-content p{
    color: #262626;
    font-size: 1rem;
    line-height: 1.3;
}

.Blog-content li{
    color: #262626;
    font-size: 1rem;
    line-height: 1.3;
}

.blog_writer_name{
    margin-top: 1.2rem;
    margin-bottom: 0.5rem;
}

.blog_time{
    font-family: gilroy-regular-italic;
}

@media (max-width: 1440px) {
    .blog_component{
        padding: 0 5rem 4rem 5rem;
        width: 60%;
    }
    .Blog-heading{
        font-size: 2rem;
        width: 70%;
        align-self: center;
    }
    .Blog_area{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

@media (max-width: 1280px) {
    .blog_component{
        margin-top: 20rem;
        padding: 0 0 4rem 0;
        width: 80%;
    }
    .Blog-heading{
        font-size: 1.7rem;
        width: 70%;
        align-self: center;
    }
    .Blog_area{
        display: flex;
        flex-direction: column;
        width: 80%;
    }
}

@media (max-width: 1024px) {
    .blog_component{
        margin-top: 20rem;
        padding: 0 0 4rem 0;
        width: 80%;
    }
    .Blog-heading{
        font-size: 1.4rem;
    }
    .Blog_area{
        width: 80%;
    }
}

@media (max-width: 768px) {
    .blog_component{
        margin-top: 20rem;
        padding: 0 0 2rem 0;
        width: 80%;
    }
    .Blog-heading{
        font-size: 1.4rem;
    }
    .Blog_area{
        width: 80%;
    }
}

@media (max-width: 600px) {
    .blog_component{
        margin-top: 14rem;
        padding: 0 0 2rem 0;
        width: 100%;
    }
    .Blog-heading{
        font-size: 1.5rem;
        width: 90%;
    }
    .Blog_area{
        width: 80%;
    }
}
