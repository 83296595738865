.chat_form{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.chat_form_input{
  border-radius: 2rem;
  font-size: 1rem;
  background-color: #E9E9E9;
  color: #333333;
  border: none;
  font-family: gilroy-regular;
  padding: 1rem 2rem;
  width: 14rem;
  margin: 0.5rem 1rem;
}

.chat_message{
  min-height: 4rem;
  resize: none;
}

.chat_send_button{
  font-family: gilroy-regular;
  width: max-content;
  padding: 0.8rem 1.5rem;
  color: white;
  cursor: pointer;
  border-radius: 2rem;
  border: none;
  background: linear-gradient(-90deg, #C271BD, #8A3585);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
}

.chat_send_button:hover{
  background: linear-gradient(-90deg, #C271BDe9, #8A3585e9);
}

.chat_send_icon{
  width: 1rem;
}

@media (max-width: 500px) and (max-height:550px) {
    .chat_form_input{
        width: 60%;
        margin: 10px;
        padding: 0.5rem 1.4rem;
    }

    .chat_message{
      grid-column: 1/3;
      justify-self: center;
      /* width: 80%; */
    }

    .chat_send_button{
      grid-column: 1/3;
      justify-self: center;
    }

    .chat_message{
        border-radius: 1.5rem;
    }
}

@media(max-width:400px){
    .chat_form_container{
        width: 100%;
    }
  .chat_form_input{
      font-size: 0.9rem;
    width: 80%;
    padding: 0.8rem 1.2rem;
    margin: 10px 0;
  }
  .chat_message{
      border-radius: 1.5rem;
  }
}
