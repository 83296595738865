.blog_page{
    background-color: #EFE0EE;
    min-height: 100vh;
    padding-bottom: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blog_img_container{
    position: absolute;
    z-index: 0;
    top: 0;
    width: 100%;
    height: 80vh;
    overflow: hidden;
}

.blog_bg_img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@media (max-width: 768px) {
    .blog_img_container{
        position: absolute;
        z-index: 0;
        top: 0;
        width: 100%;
        height: 60vh;
        overflow: hidden;
    }
    .blog_bg_img{
        margin-top: 4rem;
        width: 100%;
    }
}


@media (max-width: 600px) {
    .blog_img_container{
        position: absolute;
        z-index: 0;
        top: 0;
        width: 100%;
        height: 60vh;
        overflow: hidden;
    }
    .blog_bg_img{
        margin-top: 4rem;
        width: 100%;
    }
}
