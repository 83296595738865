  
@media only screen and (max-width: 600px) {
    #ellipsis{
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        margin: 0;
    }
  }