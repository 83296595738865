.about_us_header{
    margin-bottom: 5rem;
}

.about_us_row{
    display: flex;
    align-items: center;
    justify-content: center;
}



.img_desc{
    width: 50%;
    height: 300px;
    margin-left: 5rem;
    align-items: center;
    box-sizing: border-box;
}

.describtion{
    padding-left: 3rem;
    padding-right:1rem;
    width:50vw;
}


.about_us{
    color: #333333;
    font-size: 2.2rem;
    text-align: center;
    align-items: center;
    font-family: gilroy-extra-bold;
}

@media (max-width: 1440px){
    .img_desc{
		width: 40rem;
	}
}

@media(max-width: 1366px){
    .about_us{
		font-size: 2.2rem;
	}
}

@media(max-width: 1280px){
    .about_us{
        font-size: 2rem;
	}
}

@media(max-width:1024px){
    .img_desc{
        width: 30rem;
    }
    .about_us{
        font-size: 1.8rem;
    }
    .describtion{
        font-size: 1rem;
        width: 20rem;
    }
}

@media(max-width:768px){
    .about_us_row{
        flex-direction: column-reverse;
    }
    .img_desc{
        margin: 0;
    }
    .about_us{
        font-size: 1.8rem;
    }
    .describtion{
        font-size: 1.1rem;
        margin-top: 4rem;
    }
}

@media(max-width:600px){
    .img_desc{
        width: 19rem;
    }
    .describtion{
        font-size: 1rem;
        width: 15rem;
    }
}
