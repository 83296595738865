::-webkit-scrollbar {
    display: none;
    background: transparent;
    width: 0%;
    -ms-overflow-style: none;
  }

  .heading {
    font-size: 2.5rem;
    margin: 0;
    color: #333333;
    font-family: gilroy-extra-bold;
  }

  .TopHeading{
    display: none;
 }

  .nine_eye {
    color: #771572;
  }
  
  .search_droplist {
    width: 14rem;
    align-self: center;
    margin-bottom: 8px;
    margin-top: 3px;
    margin-left: 10px;
  }
  
  .search_box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .search_form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .search_input {
    font-family: gilroy-regular;
    margin: 20px 0;
    background-color: #f0f0f0;
    font-size: 17px;
    padding: 0.8rem 2rem;
    border-radius: 60px;
    color: #333333;
    border: none;
    width: 20rem;
  }
  
  .search_inputerror {
    font-family: gilroy-regular;
    margin: 20px 0;
    box-shadow: inset 0 0 0 2px #fcbdb8ff;
    background-color: #f0f0f0;
    font-size: 17px;
    border: none;
    padding: 0.8rem 2rem;
    border-radius: 60px;
    color: #333333;
    width: 20rem;
  }
  .search_buttons {
    display: flex;
  }
  
  .search_button {
    cursor: pointer;
    font-family: gilroy-extra-bold;
    padding: 0.8rem 2rem;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin-right: 15px;
    background: linear-gradient(90deg, #c271bd, #8a3585);
    font-size: 12pt;
    color: white;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.05);
  }
  
  
  .search_row {
    display: flex;
  }
  
  a {
    text-decoration: none;
  }
  
  .search_button:hover {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
    background: linear-gradient(90deg, #c271bde9, #8a3585e9);
  }
  
  .gps_button {
    border: none;
    text-decoration: none;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #c271bd, #8a3585);
    padding: 0.7rem;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.05);
  }
  
  .gps_button:hover {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
    background: linear-gradient(90deg, #c271bde9, #8a3585e9);
  }
  
  #gps {
    fill: #ffffff;
    width: 1.5rem;
  }
  @media (max-width: 1440px){
    .search_row {
      display: block;
    }
  }
  @media (max-width: 1366px) {
    .heading {
      font-size: 2rem;
      margin: 0;
      color: #333333;
      font-family: gilroy-extra-bold;
    }
    .search_droplist {
      align-self: center;
      margin: -1px;
      margin-bottom: 8px;
      margin-top: -8px;
    }
    .search_button {
      padding: 0.4rem 1.5rem;
      margin-right: 15px;
      font-size: 1rem;
      width: max-content;
    }
    .search_input {
      font-size: 1rem;
      padding: 0.8rem 2rem;
      width: 17rem;
    }
    .search_in .search_input {
      font-size: 1rem;
      padding: 0.8rem 2rem;
      width: 17rem;
    }
    .search_inputerror {
      font-size: 1rem;
      padding: 0.8rem 2rem;
      width: 17rem;
    }
    
    .search_category {
      margin: 0px 0px 15px;
    }
  }
  
  @media (max-width: 1024px) and (orientation: portrait) {
    .heading {
      font-size: 2.8rem;
      width: max-content;
    }
    .search_button {
      padding: 0.7rem 1.8rem;
      margin-right: 15px;
      font-size: 1.1rem;
      width: max-content;
    }
    .search_input {
      font-size: 1.1rem;
      padding: 1rem 2rem;
      width: 22rem;
    }
    .search_inputerror {
      font-size: 1.1rem;
      padding: 1rem 2rem;
      width: 22rem;
    }
  }
  
  @media (max-width: 768px) {
    .heading {
      font-size: 2rem;
      margin: 0;
      color: #333333;
      font-family: gilroy-extra-bold;
    }
    .search_button {
      padding: 0.4rem 1.5rem;
      margin-right: 15px;
      font-size: 1rem;
      width: max-content;
    }
    .search_input {
      font-size: 1rem;
      padding: 0.8rem 2rem;
      width: 17rem;
    }
    .search_inputerror {
      font-size: 1rem;
      padding: 0.8rem 2rem;
      width: 17rem;
    }
  }
  
  @media (max-width: 600px) {
    .heading {
      font-size: 1.8rem;
      width: max-content;
    }
    .search_buttons {
      width: max-content;
    }
    .search_button {
      padding: 0.4rem 1.5rem;
      margin-right: 15px;
      font-size: 1rem;
      width: max-content;
    }
    .search_input {
      width: 13rem;
    }
  
    .search_inputerror {
      width: 13rem;
    }
    .search_box {
      align-self: center;
      width: max-content;
    }
  }
 
  
  