.toggle-button{
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: space-around;
    border: none;
    border-radius: 50%;
    padding: 0;
    box-shadow: 0px 3px 10px rgba(0,0,0,0.08);
}

.toggle{
    padding: 0;
    margin: 0;
    max-width: min-content;
}
