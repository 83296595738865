@font-face{
	src: url('./components/assets/fonts/Gilroy-Regular.ttf');
	font-family: gilroy-regular;
}

@font-face{
	src: url('/assets/fonts/Gilroy-Regular.ttf');
	font-family: gilroy-regular;
}

@font-face{
	src: url('/assets/fonts/Gilroy-ExtraBold.ttf');
	font-family: gilroy-extra-bold;
}

body {
  margin: 0;
  font-family: gilroy-regular;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
