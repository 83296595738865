.query_form_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  min-height: 70vh;
  margin-top: 8rem;
}

.query_form{
  display: flex;
  flex-direction: column;
  width: 30%;
  justify-content: center;
  align-items: center;
}

.empty_error{
  color: red;
  margin-bottom: 0.5rem;
  text-align: center;
  margin-top: 10px;
  width: max-content;
}

.form_header{
  font-family: gilroy-extra-bold;
  font-size: 2.5rem;
  margin-top: 0;
}

.query_form_name{
  width: 33rem;
  display: flex;
  justify-content: space-between;
}

.first_name, .last_name{
    width: 13rem !important;
}
.form_input{
  padding: 1rem 1.5rem;
  color: #333333;
  font-size: 1rem;
  font-family: gilroy-regular;
  background-color: #e6e6e6;
  border: none;
  border-radius: 2rem;
  width: 30rem;
  margin: 0.5rem 0;
}

.message{
  min-height: 7rem;
  resize: none;
}

.query_form_button{
    cursor: pointer;
    margin-top: 1.5rem;
    border-radius: 2rem;
    padding: 0.7rem 1.7rem;
    border: none;
    font-size: 1.2rem;
    font-family: gilroy-extra-bold;
    color: #f8f8f8;
    background: linear-gradient(-90deg, #C271BD, #8A3585);
    margin-bottom:1.5rem;
}

.query_form_button:hover{
    box-shadow: 3px 3px 20px rgba(0,0,0,0.16);
    background: linear-gradient(-90deg, #C271BDe9, #8A3585e9);
}

@media only screen and (max-width: 1366px){
  .form_header{font-size: 3rem;}
  .query_form_container{min-height: 80vh;}
  .query_form_button{
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 1280px){
  .form_header{font-size: 2.5rem;}
  .query_form_container{min-height: 80vh;}
  .form_input{
    padding: 0.9rem 1.5rem;
    font-size: 1rem;
  }
  .query_form_button{
    padding: 0.6rem 1.5rem;
    font-size: 1rem;
    margin-bottom: 5rem;
  }
}
@media only screen and (max-width: 1024px){
  .form_header{font-size: 2rem;}
  .query_form_container{min-height: 60vh;}
  .form_input{padding: 0.8rem 1.5rem;
  
    font-size: 13px;
    }
  .query_form_button{
    padding: 8px 25px;
    font-size: 0.9rem;
  }
}
@media only screen 
and (min-device-width : 375px) 
and (max-device-width : 812px) 
and (orientation : portrait) { 
  .form_input{
    width: 16rem;
    margin: 0.5rem 0;
  }
  .first_name, .last_name{
    width: 6rem !important;  
  }
  .query_form_name{
    width: 19rem;
  }
 }