.Reset_page{
    height: 70vh;
    width: 100%;
    margin-top: 3.7rem;
    background-color: #F8F8F8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Reset_container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading_Reset{
    margin-top: 0;
  font-size: 2.5rem;
  font-family: gilroy-extra-bold;
  color: #333333;
}

.Reset_form{
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Reset_input{
  width: 25rem;
  margin-bottom: 1.5rem;
  appearance:none;
  background-color: #E9E9E9;
  font-size: 17px;
  padding: 1rem 2rem;
  border-radius: 60px;
  color: #333333;
  border:none;
  font-family: gilroy-regular;
}

.Reset_options_container{
  width: 25rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.Reset_options{
  text-decoration: none;
  color: #333333;

  &:hover{
    color: #B66AB1;
  }
}

.button_Reset{
  width: max-content;
  cursor: pointer;
  border-radius: 60px;
  background: linear-gradient(90deg, #C271BD, #8A3585);
  border: none;
  color: #F8F8F8;
  font-size: 1.1rem;
  font-family: gilroy-extra-bold;
  padding: 10px 25px;

  &:hover{
    background: linear-gradient(90deg, #C271BDe9, #8A3585e9);
    box-shadow: 3px 3px 20px rgba(0,0,0,0.16);

  }
}

.reset_error{
    color: red;
    margin-bottom: 1rem;
    text-align: center;
}

@media (max-width: 1280px){
  .button_Reset{
    padding: 0.6rem 1.5rem;
    font-size: 1rem;
    margin-bottom: 3rem;
  }
  .Reset_input{
    font-size: 1rem;
  }
}

@media (max-width: 1024px){
  .Reset_input{
    padding: 0.9rem 2rem;
    width: 20rem;
    font-size: 13px;
  }
  .heading_Reset{
    font-size: 2rem;
  }
  .button_Reset{
    padding: 8px 25px;
    font-size: 0.9rem;
  }

}

@media (max-width: 600px) {
  .Reset_input{
    width: 15rem;
  }
  .reset_error{
      width: 15rem;
  }
}
