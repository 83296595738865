.not_found_page{
    margin-top: 3rem;
    background-color:#EFE0EE;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.not_found_heading{
    margin-top: 0;
    font-family: gilroy-extra-bold;
}

.not_found_content{
    margin-bottom: 2rem;
}
.not_found_button{
    font-size: 1.2rem;
    cursor: pointer;
    text-align: center;
    color: #f8f8f8;
    border: none;
    border-radius: 2rem;
    padding: 1rem 2rem;
    font-family: gilroy-extra-bold;
    background: linear-gradient(90deg,#C271BD,#8A3585);
}

.not_found_button:hover{
    background: linear-gradient(90deg, #C271BDe9, #8A3585e9);
    box-shadow: 3px 3px 20px rgba(0,0,0,0.16);
}

.not_found_img{
    width: 45%;
    align-items: center;
}
